/* eslint-disable @typescript-eslint/no-unused-vars */

import { Configuration } from '@/models/order/Configuration'
import { Order } from '@/models/order/Order'

/**
 * * Generate Pimcore Timestamp according to installation time
 *
 * @param date YYYY-MM-DD
 * @param am if installation is in the morning
 * @param pm if installation is in the afternoon
 * @returns YYYY-MM-DD HH:MM:SS (pimcore timestamp)
 */
export const generateInstallationDate = (date: string, am: boolean, pm: boolean, evening: boolean): string => {
  // Pôvodne keď sme cez GQL uložili dátum do fieldu installationDate v Pimcore,
  // odoslal sa string vo formáte YYYY-MM-DD. Pimcore upravil tento TS tak, aby pribudol čas 00:00:00
  // ! Problém: keď sa cez GQL opätovne získal tento timestamp cez query, konštruktor JS Date to vzal nevhodne,
  // teda posunul to o 2h skôr... takže z 2022-10-08 00:00:00 bolo GMT+2 → 2022-10-07 22:00:00
  // keďže nebola možnosť ovplyvniť chovanie vstavanej triedy Date v JS, radšej budeme ukladať "rámcové časy",
  // napr. 8am pre ráno a 2pm poobednú montáž. Možno sa čas využije, avšak teraz nieje tento údaj v timestampe použitý
  if (am) return `${date} 07:30:00`
  if (pm) return `${date} 12:30:00`
  if (evening) return `${date} 17:00:00`

  return `${date} 07:30:00`
}

export const allowAssemblyProtocolEnter = (o: Order, c: Configuration) => {
  const errors: string[] = []


  // if (!o.orderNumber) errors.push('ORDER_NUMBER')
  if (!c.name) errors.push('jméno konfigurace')
  if (!o.contactData.firstName || !o.contactData.lastName) errors.push('kontaktní jméno')
  if (!c.street || !c.streetNumber || !c.postcode || !c.city) errors.push('adresa montáže')

  // Montér && Podpis zástupce montážní skupiny
  if (!c.workerGroup.id) errors.push('montážní skupina') // CONF_WG
  if (!c.installationDate) errors.push('datum montáže') // CONF_INSTALLDATE
  if (!c.workerGroup.leadingWorker.id) errors.push('vedoucí montážní skupiny') // CONF_WG_LEAD

  return errors
}


export const generateProtocolComments = () => ([
  { id: 0, label: 'Montáž bezpečnostních dveří proběhla bez vad a nedodělků',         value: true },
  { id: 1, label: 'Dveře i zárubně jsou předány čisté',                               value: true },
  { id: 2, label: 'Byl proveden úklid & na místě se nenachází poškození podlahoviny', value: true },
  { id: 3, label: 'Objednatel byl obeznámen s obsluhou',                              value: true },
  { id: 4, label: 'Dílo přijímá bez výhrad',                                          value: true },
])

export const generateProtocolPersonallyComments = () => ([
  { id: 5, label: 'Byl předán doklad o montáži',                                      value: true },
  { id: 6, label: 'Objednatel převzal fakturu',                                       value: true },
])
